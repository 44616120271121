<template>
  <div
    :id="organism.id ?? ''"
    :class="[
      organism.containerWidth ? 'container-' + organism.containerWidth : '',
      organism.fullBleed ? 'full-bleed md:py-0' : '',
      'organism split-content py-8 sm:py-14',
    ]"
    :style="'background-color:' + organism.backgroundColor"
  >
    <div
      v-if="
        organism.splitContentLayout === 'gallery-text' ||
        organism.splitContentLayout === 'text-gallery'
      "
      :class="[
        'content mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 xl:gap-24 items-start justify-center px-8',
        organism.splitContentLayout,
      ]"
    >
      <MoleculesText :text="organism" />
      <div class="swiper-container">
        <Swiper
          :navigation="true"
          :loop="true"
          :modules="modules"
          :pagination="true"
          :autoplay="autoplaySlider()"
          class="h-auto"
        >
          <SwiperSlide
            v-for="(image, index) in organism.gallery"
            :key="'slider-image-' + index"
            class="select-none"
          >
            <img
              class="w-full aspect-[3/2] img-primary"
              :src="getImageSrc(image, 'large')"
              alt=""
              loading="lazy"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div
      v-else-if="
        organism.splitContentLayout === 'text-video' ||
        organism.splitContentLayout === 'video-text'
      "
      :class="[
        'content mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 xl:gap-24 items-start justify-center px-8',
        organism.splitContentLayout,
      ]"
    >
      <MoleculesText :text="organism" />
      <div
        :class="[
          'video-embed aspect-[3/2] md:aspect-[10/9] w-[490px] max-w-full mx-auto max-h-[250px] md:max-h-full',
          organism.splitContentLayout === 'video-text' ? 'order-first' : '',
        ]"
        v-html="organism.videoEmbedCode"
      ></div>
    </div>
    <div
      v-else-if="
        organism.splitContentLayout === 'text-virtualtour' ||
        organism.splitContentLayout === 'virtualtour-text'
      "
      :class="[
        'content mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 xl:gap-24 items-start justify-center px-8',
        organism.splitContentLayout,
      ]"
    >
      <MoleculesText :text="organism" />
      <div
        v-if="organism.virtualTourEmbedType === 'url'"
        :class="[
          'w-full max-w-full lg:max-w-2xl min-h-[400px]',
          organism.virtualTourEmbedType,
        ]"
        v-html="getEmbedCodeByUrl(organism.virtualTourUrl)"
      ></div>
      <div
        :class="[
          'w-full max-w-full lg:max-w-2xl min-h-[400px]',
          organism.virtualTourEmbedType,
          organism.splitContentLayout === 'virtualtour-text'
            ? 'order-first'
            : '',
        ]"
        v-else-if="organism.virtualTourEmbedType === 'custom-embed-code'"
        v-html="organism.virtualTourCustomEmbedCode"
      ></div>
    </div>
    <div
      v-else-if="
        organism.splitContentLayout === 'text-text'
      "
      :class="[
        'content mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 xl:gap-24 items-start justify-center px-8',
        organism.splitContentLayout,
      ]"
    >
      <MoleculesText :text="organism" />
      <div v-if="organism.descriptionTwo" class="md:mt-0 mt-8">
        <div
          v-html="organism.descriptionTwo"
          class="description-two"
        ></div>
      </div>
    </div>
    <div
      v-else
      :class="[
        'content mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-24 items-center justify-center px-8',
        organism.splitContentLayout,
      ]"
    >
      <MoleculesText :text="organism" />
      <div
        v-if="organism.videoEmbed"
        class="video-embed aspect-[3/2] md:aspect-[10/9] w-[490px] max-w-full mx-auto max-h-[250px] md:max-h-full"
        v-html="organism.videoEmbed"
      ></div>
      <img
        v-else
        class="aspect-[3/2] md:aspect-[10/9] object-cover w-[490px] max-w-full mx-auto max-h-[250px] md:max-h-full img-primary"
        :src="getImageSrc(organism.image, 'large')"
        :alt="organism.image?.alt"
        loading="lazy"
      />
    </div>
  </div>
</template>

<style>
.split-content {
  .swiper {
    --swiper-theme-color: #559cbe;
    --swiper-navigation-size: 1.5rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @apply bg-white w-10 h-10 rounded-full shadow-lg;
  }

  .swiper-button-prev {
    @apply left-1 md:left-[10px];
  }

  .swiper-button-next {
    @apply right-1 md:right-[10px];
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    @apply bg-gray-200;
  }

  .description, .description-two {
    ul {
      @apply ml-6 list-disc list-outside mb-8;

      li {
        @apply mb-1 text-fr-text-med;
      }
    }

    p {
      @apply text-fr-text-med;
    }
  }

  .text-image {
    @apply xl:grid-cols-[600px_490px];

    img.img-primary,
    .video-embed {
      @apply -order-1 md:order-2;
    }
  }

  .image-text {
    @apply xl:grid-cols-[490px_600px];

    img.img-primary,
    .video-embed {
      @apply -order-1;
    }
  }

  .text-video {
    @apply xl:grid-cols-[600px_490px];
    .video-embed {
      @apply -order-1 md:order-2;
    }
  }
  .video-text {
    @apply xl:grid-cols-[490px_600px];
    .video-embed {
      @apply -order-1;
    }
  }

  .text-gallery {
    .swiper-container {
      @apply -order-1 md:order-2;
    }
  }

  .gallery-text {
    .swiper-container {
      @apply -order-1;
    }
  }

  &.full-bleed {
    .text-image {
      @apply xl:grid-cols-[1fr_1fr] gap-4;

      .text {
        @apply xl:max-w-[600px] xl:ml-auto md:py-14 md:pl-8;
      }

      img.img-primary {
        @apply md:w-full md:aspect-auto md:h-full md:ml-0 md:max-w-2xl;
      }
    }
    .image-text {
      @apply xl:grid-cols-[1fr_1fr] gap-4;

      .text {
        @apply xl:max-w-[600px] xl:mr-auto md:py-14 md:pr-8;
      }

      img.img-primary {
        @apply md:w-full md:aspect-auto md:h-full md:mr-0 md:max-w-2xl;
      }
    }
  }

  .video-embed iframe {
    @apply max-w-full max-h-[250px] md:max-h-full w-full h-full;
  }

  .title-1 {
    @apply mb-6 text-gray-800;
  }

  .description {
    @apply text-lg font-figtree text-gray-500 leading-8 mb-0 sm:mb-8;
  }

  .buttons a {
    @apply uppercase text-[15px] font-figtree font-medium py-3;
  }
}

.organism-slider .split-content .content {
  @apply px-16;
}
</style>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const modules = [Navigation, Pagination, Autoplay];

const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});

const autoplaySlider = () => {
  if (props.organism.autoplaySlider && props.organism.autoplayDelay) {
    return { delay: props.organism.autoplayDelay };
  }

  return false;
};
</script>
